.widget-book-now {
    --min-height: 50vh;
    --button-h: #{rem(54px)};

    @media (min-width: 480px) {
        --padding-v: var(--header-height);
        --padding-h: var(--padding-xxl);
    }

    @media (max-width: 480px) {
        --padding-v: var(--header-height);
        --padding-h: 10px;
    }
}

.widget-book-now {
    width: 100%;
    min-height: var(--min-height);
    padding: var(--padding-v) var(--padding-h);
}

.mi-bsmodal-dialog,
.mi-rs {
    @include font-sans();

    * {
        @include font-sans();
    }
}

.mi-cr-header__button-map.mi-s-color-link {
    color: var(--green) !important;
}

[class^="mi-"] .mi-s-button, [class^="mi-"] .mi-s-color-button, .mi-be .mi-be-book-btn, .mi-rs .mi-rs-cart-btn, .mi-rs .mi-rs-add-btn, .mi-rs .mi-na-option-date-btn, .mi-rs .mi-na-option-hotel-btn, .mi-rs .mi-cf-form-data-btn, button.mi-modal-close-btn, .mi-rs .mi-rs-rate-select-room-btn, .mi-be-mobile .mi-be-modal .mi-be-multiroom-footer .mi-be-multiroom-footer-confirm, .mi-be-mobile .mi-be-modal .mi-be-modal-footer .mi-be-date-picker-confirm {
    background-color: var(--dark-green);
    color: #fff;

    &:hover {
        background-color: var(--green);
    }
}

.mi-cr-mapmodal .mi-cr-button, .mi-cr .mi-cr-button {
    border-radius: var(--padding-xxxs) !important;
}

@media (max-width: 480px) {
    .mi-l-container.mi-cr-header__container {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
}