:root {
    --white: #ffffff;
    --black: rgba(6,8,4,1);
    --grey: #999;

    --primary: var(--black);
    --secondary: var(--white);
    --fluor: #7eff84;

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #7EFF84;

    --dark-green: #041F1E;
    --green: #607F72;
    --green15: rgba(96, 127, 114, .15);
    --light-green: #7EFF84;
    --lighter-green: #E7ECEA;
}
