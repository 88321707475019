#Sidemenu {
    --bg: var(--black);
    --color: var(--white);
    --font-size: var( --font-size-xlarge);
    --font-size-lang: var( --font-size-small);
    --width-locations: #{rem(410px)};
    --padding-locations: #{rem(80px)};
    --padding-v-locations: #{rem(65px)};
    --max-width: calc(var(--width-locations) + var(--padding-locations) * 2);
    --padding-v: 0;
    --padding-v-bottom: 0;
    --padding-h: 0;
    --gap-locations: var(--padding-l);
    --display-nav: none;

    @media (max-width: $smartphone) {
        --display-nav: block;
        --padding-v: #{rem(80px)};
        --padding-v-bottom: #{rem(40px)};
        --padding-h: var(--padding-s);
        --padding-v-locations: 0;
        --padding-locations: var(--padding-xxs);
        --max-width: 100%;
    }
}



#Sidemenu {
    position: fixed;
    @include z-index($z-index-sidemenu);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
   
    padding: var(--padding-v) var(--padding-h) var(--padding-v-bottom);

    color: var(--color);
    font-size: var(--font-size);

    @media (max-width: $smartphone) {
        background-color: var(--bg);
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    > .fake-button {
        position: absolute;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: .2;
    }

    > .content {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: var(--max-width);
        background-color: var(--bg);
        overflow: hidden;

        @media (max-width: $smartphone) {
            height: auto;
            overflow: visible;
        }

        > nav {
            position: relative;
            display: var(--display-nav);

            .sidemenu__logo {
                @include basic-a();
                padding-top: var(--padding-s);
                display: flex;
                justify-content: center;
                align-items: center;
                                
                svg {
                    position: relative;
                    width: 140px;
                    height: auto;
                    fill: var(--color);
                }
            }
            
            .sidemenu__link {
                @include basic-a();
                display: block;
                padding: .2em 0;
                                
                @include font-serif(1);
                font-size: var(--font-size);
                color: var(--color);
                text-align: center;
                text-transform: uppercase;
            }
            
            > a:last-of-type {
                margin-bottom: var(--padding-l);
            }
        }
    }
}

.sidemenu__lang-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-lang);
    margin-bottom: var(--padding-s);

    .icon {
        fill: var(--color);
        width: 22px;
        height: 22px;
        margin-left: -32px;
        margin-right: 10px
    }

    a {
        @include basic-a();
        @include font-sans-bold(1);
       
        color: var(--color);
        padding: 1em .5em;
        text-transform: uppercase;
        
        &.is-active {
            opacity: .52;
            pointer-events: none;
        }
    }
}

.button-kora.sidemenu__toggle-button {
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-xs);
    
    position: absolute;
    display: var(--display-btn-toogle);
    @include z-index($z-index-book-button);
    top: var(--padding-v);
    left: var(--padding-h);
}

.sidemenu__location-ul {
    position: relative;
    list-style: none;
    padding: var(--padding-v-locations) var(--padding-locations);
    
    li {
        display: block;
        will-change: transform;
        
        &:not(:last-child) {
            margin-bottom: var(--gap-locations);
        }
    }
}